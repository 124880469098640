<template>
  <img :src="invoiceLogo" height="70px" :alt="invoiceLogo" />
</template>
<script>
export default {
  props: {
    invoiceLogo: {
      type: String,
    },
  },
};
</script>
